export class StringTemplate {
    constructor(settings, performanceContainer) {
        this.settings = settings;
        this.performanceContainer = performanceContainer;

        this.timeFrame = {};
    }

    getChartTitle() {
        return this.getTemplateString('title');
    }

    getChartSubTitle() {
        return this.getTemplateString('subTitle');
    }

    getChartXAxisTitle() {
        return this.getTemplateString('xAxisTitle');
    }

    getChartYAxisTitle() {
        return this.getTemplateString('yAxisTitle');
    }

    getLoadingMessageString() {
        return this.settings.loading_message ? this.settings.loading_message : 'Daten werden geladen...';
    }

    getTemplateString(settingsKey, defaultString = '') {
        let string = this.settings[settingsKey];
        if (string == undefined || string == null) {
            string = defaultString;
        }

        return this.replacePlaceholder(string);
    }

    replacePlaceholder(text) {
        let placeholder = this.getPlaceholder();
        Object.keys(placeholder).forEach((key) => {
            text = text.replace(new RegExp(`{{${key}}}`, 'g'), placeholder[key]);
        })

        return text;
    }

    getPlaceholder() {
        return {
            PERFORMANCE: this.performanceContainer.getCurrentPerformanceName(),
            TIME_FRAME_FROM: moment(this.timeFrame.from).format('D.M.YYYY'),
            TIME_FRAME_UNTIL: moment(this.timeFrame.until).format('D.M.YYYY'),
            AUDIENCE_TITLE: this.settings.filters ? this.settings.filters.map((a) => a.title).join(', ') : ''
        }
    }

    // information setter

    setTimeFrame(frame) {
        this.timeFrame = frame;
    }
}
