import {CalcBackend, AudienceManager, MediaManager} from './backend/calc'
import {ApiBackend} from './backend/api'
import {NumberFormatter} from './resources/numberFormatter'
import {Export} from './resources/export'
import {ChartSeries} from './resources/chart-series'
import {StringTemplate} from './resources/string-template'
import {PerformanceContainer} from './resources/performance'

export default class DDFGraphics {

    constructor(domId, settings) {
        this.calcBackend = new CalcBackend();
        this.export = new Export();
        this.performanceContainer = new PerformanceContainer();

        this.api = new ApiBackend();
        this.api.setLoadCallback(this.onDataLoaded.bind(this));

        let audiences = [];
        if (settings.filters) { audiences = settings.filters; }
        this.audienceHelper = new AudienceManager(audiences);

        let media = [];
        if (settings.media) { media = settings.media; }
        this.mediaHelper = new MediaManager(media);

        ////////////////////////////////////////

        this.settings = settings;
        this.containerDomRef = domId;

        this.stringTemplate = new StringTemplate(this.settings, this.performanceContainer);

        this.container;
        this.chart;
        this.lastSize = {width: 0, height: 0};

        this.sizeCheckIntervall = 200;

        this.cacheData;

        this.frame = 'week';

        this.chartType = 'line';
        if (this.settings.chart) {
            if (['column', 'line', 'bar', 'pie'].indexOf(this.settings.chart.toLowerCase()) !== -1) {
                this.chartType = this.settings.chart.toLowerCase();
            }
        }

        this.viewType = 'days';
        if (this.settings.viewType != undefined) {
            this.viewType = this.settings.viewType;
        }

        this.legend = true;
        if (this.settings.legend != undefined) {
            this.legend = this.settings.legend;
        }

        this.displayWmGames = false;
        if (this.settings.displayWmGames != undefined) {
            this.displayWmGames = this.settings.displayWmGames;
        }

        this.performanceContainer.setPerformance(this.settings.performance)

        if (this.settings.frame) {
            if (['week', '2weeks', 'month'].indexOf(this.settings.frame.toLowerCase()) !== -1) {
                this.frame = this.settings.frame.toLowerCase();
            }
        }

        Highcharts.setOptions({
            lang: {
                decimalPoint: ',', thousandsSeparator: '.',
                numericSymbols: [' Tsd', ' Mio']
            }
        });

        moment.locale('de');

        $(document).ready(() => {
            this.container = $(this.containerDomRef);

            if (this.settings.media || this.settings.dataUrl) {
                this.requestData();
            }

            this.lastSize.height = this.container.height();
            this.lastSize.width = this.container.width();
            this.checkForChanges();
        });
    }


    checkForChanges() {
        if (this.container.height() != this.lastSize.height || this.container.width() != this.lastSize.width) {
            this.lastSize.height = this.container.height();
            this.lastSize.width = this.container.width();
            this.onDrawContainerResize();
        }

        setTimeout(this.checkForChanges.bind(this), this.sizeCheckIntervall);
    }

    onDrawContainerResize() {
        this.chart.setSize(this.container.width(), this.container.height(), true);
    }

    displayLoadingMessage() {
        this.container.append(`<p>${this.stringTemplate.getLoadingMessageString()}</p>`);
    }

    changeChartType(newType) {
        if (['column', 'line', 'bar'].indexOf(newType.toLowerCase()) !== -1) {
            this.chartType = newType.toLowerCase();
            this.requestData();
        }
    }

    setMedia(media) {
        this.mediaHelper.setMedia(media);
        this.requestData();
    }

    setFilters(filters) {
        this.audienceHelper.setFilters(filters);
        this.requestData();
    }

    setPeriod(period) {
        this.frame = period;
        this.requestData();
    }

    setPerformance(performance) {
        this.performanceContainer.setPerformance(performance)
        this.requestData();
    }

    requestData() {
        this.displayLoadingMessage();
        let filterKeys = this.audienceHelper.getFilterKeys();

        if (this.settings.dataUrl) {
            return this.api.loadDataUrl(this.settings.dataUrl, this.settings.date, filterKeys, this.settings.performance);
        }

        let mediaKeys = this.mediaHelper.getMediaKeys();
        return this.api.load(mediaKeys, this.settings.date, this.frame, filterKeys);
    }

    onDataLoaded(response) {
        let generator = new ChartSeries(response.data, this.mediaHelper, this.audienceHelper, this.performanceContainer, this.viewType);

        this.mediaHelper.setMedia(generator.getMedia());

        let frame = generator.getTimeframe()
        if (response.info && response.info.frame) {
            frame = response.info.frame;
        }

        this.stringTemplate.setTimeFrame(frame);

        let media = generator.getMediaTitle().join(', ');
        let categories = generator.createCategories(this.settings);
        let dataSeries = generator.createSeries(this.performanceContainer.getCurrentPerformance());

        if (this.chartType == 'pie') {
            dataSeries = generator.createPieSeries(this.performanceContainer.getCurrentPerformance());
        }

        let baseXAxisObject = {
            categories: categories,
            title: { text: this.stringTemplate.getChartXAxisTitle() },
            labels: { style: { fontSize: '10px', } }
        }



        let chartConfig = {
            chart: { type: this.chartType },
            title: { text: this.stringTemplate.getChartTitle() },
            subtitle: { text: this.stringTemplate.getChartSubTitle() },
            xAxis: baseXAxisObject,
            credits: { enabled: false },

            tooltip: {
                // headerFormat: '<span style="font-size: 10px">{point.key} - {series.name}</span><br/>',
                crosshairs: true,
                shared: true,
                pointFormatter: this.tooltipFormatter
            },
            performance: this.performanceContainer.getCurrentPerformance(),
            legend: { enabled: this.legend },
            yAxis: {
                title: {
                    text: this.stringTemplate.getChartYAxisTitle()
                },
                labels: {
                    formatter: this.axisLabelFormater,
                    rotation: 0
                }
            },
            series: dataSeries
        }
        
        if (this.chartType == 'pie') {
            chartConfig.tooltip = {
                useHTML: true,
                formatter: this.pieTooltipFormatter
            }
        }

        if (this.displayWmGames) {
            let wmGamesCategories = generator.createWMGamesCategories(this.settings);
            let _this = this
            let xAxis = [
                baseXAxisObject,
                {
                    linkedTo: 0,
                    tickLength: 0,
                    lineWidth: 0,
                    categories: wmGamesCategories,
                    labels: {
                        style: {
                            'font-size': '9px',
                        },
                        useHTML: true,
                        rotation: -45,
                        formatter: function() {
                            let formatedValue = this.value.toString().replace(new RegExp('\n', 'g'), '<br/>');
                            return _this.formatWithFlags(formatedValue);
                        }
                    }
                }
            ]

            chartConfig.xAxis = xAxis;
        }

        // TODO: need to be a config property!
        if (this.chartType === 'line') {
            chartConfig.yAxis.min = 0;
        }

        this.chart = Highcharts.chart(this.container.get(0), chartConfig);
    }

    formatWithFlags(value) {
        let placeholderMatches = value.match(/\FLAG:(\w+):/g)
        if (!placeholderMatches) { return value; }


        placeholderMatches.forEach((placeholder) => {
            let flagKeyMatches = placeholder.match(/\:(\w+):/g)
            if (!flagKeyMatches) { return true; }

            flagKeyMatches.forEach((flagKey) => {
                let code = flagKey.replace(new RegExp(':', 'g'), '').toLowerCase()

                let replaceStr = `<img class="flag flag-${code}"/>`;
                if (code == 'xx') {
                    replaceStr = '??';
                }

                value = value.replace(placeholder, replaceStr);
            });
        });

        return value;
    }

    axisLabelFormater() {
        this.numberFormatter = new NumberFormatter();
        return this.numberFormatter.formatNumber(this.value, this.chart.userOptions.performance)
    }

    tooltipFormatter() {
        this.numberFormatter = new NumberFormatter();
        let value = this.numberFormatter.formatNumber(this.y, this.series.options.performance)

        return `<span style="color:${this.series.color}">${this.series.name}</span>: ${value} ${this.series.options.performanceSuffix}<br/>`;
    }

    pieTooltipFormatter() {
        this.numberFormatter = new NumberFormatter();
        let value = this.numberFormatter.formatNumber(this.y, this.series.options.performance)

        let toolTip  = '<table><tr>';
        toolTip += `  <td><span>${this.key}</span></td>`;
        toolTip += `  <td class="Eval-ToolTip-Data-Cell">: ${value} ${this.series.options.performanceSuffix}</td>`;
        toolTip += '</tr></table>';

        return toolTip;
    }

    exportChart(type) {
        this.export.exportChart(this.chart, type)
    }
}

window.DDFGraphics = DDFGraphics;
