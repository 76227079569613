export class CalcBackend {

    constructor() {
        // console.log("init calcBackend 2")
    }

}


export class GenericCalcElement {
    constructor(cfg) {
        this._key = cfg.key ? cfg.key : undefined;
        this._title = cfg.title ? cfg.title : undefined;

        this._color = cfg.color ? cfg.color : undefined;

        this._rawCfg = Object.assign({}, cfg);
    }

    data() {
        let self = { key: this._key, title: this._title };
        if (this._color) { self.color = this._color; }
        return self;
    }

    key() {
        return this._key;
    }

    title(template) {
        return this._title;
    }

    createTitleWithTemplate(text) {
        let keys = ['display', 'title', 'longTitle', 'shortTitle', 'key'];

        keys.forEach((key) => {
            let pattern = new RegExp(`{{${key}}}`, 'g');
            if (pattern.test(text)) {
                text = text.replace(pattern, this._rawCfg[key]);
            }
        });

        return text;
    }
}


export class AudienceManager {
    constructor(filters) {
        this.audiences = [];
        this.setFilters(filters);
    }

    setFilters(filters) {
        this.audiences = filters.map((filter) => new GenericCalcElement(filter));
    }

    getFilterKeys() {
        return this.audiences.map((audience) => audience.key());
    }

    getTitleForKey(key) {
        let audience = this.audiences.find((audience) => audience.key() == key);
        return audience.title ? audience.title() : undefined;
    }

    getColorForKey(key) {
        let audience = this.audiences.find((audience) => audience.key() == key);
        if (!audience) { return undefined; }
        let audienceData = audience.data();
        return audienceData.color ? audienceData.color : undefined;
    }

    getByKey(key) {
        return this.audiences.find((audience) => audience.key() == key);
    }
}


export class MediaManager {
    constructor(media) {
        this.media = [];
        this.setMedia(media);
    }

    setMedia(media) {
        this.media = media.map((medium) => new GenericCalcElement(medium));
    }

    getMediaKeys() {
        return this.media.map((medium) => medium.key());
    }

    getTitleForKey(key) {
        let medium = this.media.find((medium) => medium.key() == key);
        if (medium) {
            return medium.title ? medium.title() : undefined;
        }

        return undefined;
    }
}
