export class ChartSeries {

    constructor(data, mediaManager, audienceManager, performanceContainer, viewType) {
        this.data = JSON.parse(JSON.stringify(data));
        this.mediaManager = mediaManager;
        this.audienceManager = audienceManager;
        this.performanceContainer = performanceContainer;

        this.viewType = viewType;
    }

    getFirstDataRow() {
        if (!this.data.length) {
            console.warn('not enough media data');
            return null;
        }


        return JSON.parse(JSON.stringify(this.data[0]));
    }

    getTimeframe() {
        let row = this.getFirstDataRow();
        if (row == null) { return; }

        return row.info.timeframe;
    }

    getMedia() {
        let media = [];
        this.data.forEach((row) => {
            if (row.info != undefined) {
                media.push(row.info.medium);
            }
        });

        return media;
    }

    getMediaTitle(cfg) {
        return this.getMedia().map((medium) => {
            if (cfg && cfg.templates && cfg.templates.mediumDisplay) {
                return this.createMediaTitleWithTemplate(medium, cfg.templates.mediumDisplay);
            }
            return medium.title;
        });
    }

    createMediaTitleWithTemplate(medium, template) {
        Object.keys(medium).forEach((key) => {
            let pattern = new RegExp(`{{${key}}}`, 'g');
            if (pattern.test(template) && medium[key]) {
                template = template.replace(pattern, medium[key]);
            }
        });

        return template;
    }

    createCategories(cfg) {
        if (this.viewType == 'media') {
            return this.getMediaTitle(cfg);
        }

        if (this.viewType == 'days') {
            let row = this.getFirstDataRow();
            if (row == null) { return; }

            if (!row.data.length) {
                console.warn('not enough filter data');
                return [];
            }

            return row.data[0].data.map((entry) => moment(entry.date).format('dd D.M.YY')).reverse();
        }

        return []
    }

    createWMGamesCategories() {
        let row = this.getFirstDataRow();
        if (row == null) { return; }

        if (!row.data.length) {
            console.warn('not enough filter data');
            return [];
        }

        return row.data[0].data.map((entry) => entry.games).reverse();
    }


    createSeries(performanceKey) {
        let series = [];

        if (this.viewType == 'media') {
            this.audienceManager.audiences.forEach((audience, index) => {
                let audienceSeries = {
                    name: audience.title(),
                    performance: performanceKey,
                    performanceName: this.performanceContainer.getNameByPerformance(performanceKey),
                    performanceSuffix: this.performanceContainer.getSuffixByPerformance(performanceKey),
                    data: []
                };

                this.data.forEach((medium) => {
                    if (medium.data == undefined) { return true; }

                    audienceSeries.data.push(medium.data[index].data[0][performanceKey])
                });

                series.push(audienceSeries)
            })
        }

        if (this.viewType == 'days') {
            this.data.forEach((medium) => {
                if (medium.data == undefined) { return true; }

                medium.data.forEach((audience) => {

                    let color = this.audienceManager.getTitleForKey(audience.info.key)

                    let title = {
                        audience: this.audienceManager.getTitleForKey(audience.info.key),
                        medium: this.mediaManager.getTitleForKey(medium.info.medium.key)
                    };

                    let audienceSeries = {
                        name: `${title.medium}`,
                        performance: performanceKey,
                        performanceName: this.performanceContainer.getNameByPerformance(performanceKey),
                        performanceSuffix: this.performanceContainer.getSuffixByPerformance(performanceKey),
                        data: audience.data
                            .map((e) => {
                                if (e[performanceKey] == undefined) {
                                    return null;
                                } else {
                                    return e[performanceKey];
                                }
                            })
                            .reverse()
                    };

                    let audienceColor = this.audienceManager.getColorForKey(audience.info.key);
                    if (audienceColor !== undefined) {
                        audienceSeries.color = audienceColor;
                    }

                    series.push(audienceSeries)
                });
            });
        }

        return series;
    }

    createPieSeries(performanceKey) {
        let series = [];

        this.audienceManager.audiences.forEach((audience, index) => {
            let pieSliceSerie = {
                performance: performanceKey,
                performanceName: this.performanceContainer.getNameByPerformance(performanceKey),
                performanceSuffix: this.performanceContainer.getSuffixByPerformance(performanceKey),
                data: []
            };

            this.data.forEach((medium) => {

                if (medium.data == undefined) { return true; }

                pieSliceSerie.data.push({
                    name: medium.info.medium.shortTitle,
                    y: medium.data[index].data[0][performanceKey]
                })
            });

            series.push(pieSliceSerie)
        });
        return series;
    }
}
