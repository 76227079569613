export class Export {

    exportChart(chart, type) {
        let chartConfig = this.getChartConfig(chart);

        let theForm = document.exportChartForm;
        theForm.type.value = type;
        theForm.data.value = JSON.stringify(chartConfig);
        theForm.submit();
    }

    getChartConfig(chart) {
        let availableChartTypes = ["bar", "column", "pie", "spider", "line"]
        if (typeof(chart) != "undefined" && availableChartTypes.indexOf(chart.options.chart.type) > -1) {
            return this.prepareChartConfig(chart)
        }

        return {};
    }

    prepareChartConfig (chart) {
        let chartConfig = jQuery.extend(true, {}, chart.userOptions)

        let theLastChartConfigs = {};
        for (let i = 0; i < chart.axes.length; i++) {
          let theAxesName = chart.axes[i].coll
          if (!(theLastChartConfigs.hasOwnProperty(theAxesName))){
            theLastChartConfigs[theAxesName] = {}
          }

          theLastChartConfigs[theAxesName] = chart.axes[i]
        }

        let theSeries = [];
        for (let i = 0; i < chartConfig.series.length; i++) {
            // color
            if (typeof(chartConfig.series[i].color) == "undefined") { chartConfig.series[i].color = chart.series[i].color; }
            // ist series hidden ?
            if (chartConfig.series[i].visible != false) {
              if (typeof (chartConfig.series[i]["performance"]) != "undefined") {
                if (["sampleByRow", "sampleByCol", "affinityIndex"].indexOf(chartConfig.series[i]["performance"]) > -1) {
                  let theData = []

                  for (let j = 0; j < chartConfig.series[i]["data"].length; j++) {
                    let theDataItem = chartConfig.series[i]["data"][j]
                    if (chart.options.chart.type == "pie") {
                      if (theDataItem[1] != null) { theDataItem[1] = theDataItem[1].toFixed(1) }
                    } else {
                      if (theDataItem != null) { theDataItem = theDataItem.toFixed(1) }
                    }
                    theData.push(theDataItem)
                  }

                  chartConfig.series[i]["data"] = theData
                }
              }

              theSeries.push(chartConfig.series[i])
            };
        }

        chartConfig.series = theSeries

        if (chart.options.chart.type == "bar") {
            if ( typeof(chartConfig.yAxis) != "undefined" ) {

                chartConfig.yAxis.orderReverse = true;
                chartConfig.xAxis.showAxisLine = false
                chartConfig.yAxis.crossType = "maximum";

                chartConfig.chart.overlaps = "-30";

                if (typeof(theLastChartConfigs["yAxis"]) != "undefined") {
                  chartConfig.xAxis.tickInterval = theLastChartConfigs["yAxis"]["tickInterval"];
                  chartConfig.xAxis.max = theLastChartConfigs["yAxis"]["max"];
                }
            }
            chartConfig.series.reverse();
        }

        if (chart.options.chart.type == "column") {
            if ( typeof(chartConfig.chart) != "undefined" ) {
              chartConfig.chart.overlaps = "-30";
              chartConfig.yAxis.showAxisLine = false

              if (typeof(theLastChartConfigs["yAxis"]) != "undefined") {
                chartConfig.yAxis.tickInterval = theLastChartConfigs["yAxis"]["tickInterval"];
                chartConfig.yAxis.max = theLastChartConfigs["yAxis"]["max"];

                chartConfig.xAxis.showGridLine = false
                chartConfig.yAxis.showGridLine = true
              }
            }
        }

        if (chart.options.chart.type.indexOf("line") >= 0) {
            if ( typeof(chartConfig.chart) != "undefined" ) {
                chartConfig.chart.type = "line";
                chartConfig.yAxis.showAxisLine = false

                if (typeof(theLastChartConfigs["yAxis"]) != "undefined") {
                  chartConfig.yAxis.tickInterval = theLastChartConfigs["yAxis"]["tickInterval"];
                  chartConfig.yAxis.max = theLastChartConfigs["yAxis"]["max"];

                  chartConfig.xAxis.showGridLine = false
                  chartConfig.yAxis.showGridLine = true
                 }
            }
        }

        if (chart.options.chart.type == "pie") {
            if ( typeof(chartConfig.chart) != "undefined" ) {
                chartConfig.chart.type = "pie";
            }

            chartConfig.colors = ['#2a2e35', '#4f5866', '#79889f', '#a5bbd8', '#b4c9e1', "#c7d6e9", '#d9e3f1', '#ecf1f6'];
        }

        if (chart.options.chart.type == "spider") {
            if ( typeof(chartConfig.chart) != "undefined" ) {
                chartConfig.chart.type = "radar";
                chartConfig.plotOptions.radar = chartConfig.plotOptions.line
                chartConfig.yAxis.showGridLine = true

                if (typeof(theLastChartConfigs["yAxis"]) != "undefined") {
                  chartConfig.yAxis.tickInterval = theLastChartConfigs["yAxis"]["tickInterval"];
                }
            }
        }

        if (chart.options.chart.type == "intersection") {
            if ( typeof(chartConfig.chart) != "undefined" ) {
                chartConfig.chart.type = "stacked bar";
                chartConfig.chart.overlaps = "100"
                chartConfig.xAxis.showAxisLine = false
                chartConfig.legend.position = "bottom"

                chartConfig.yAxis.orderReverse = true;
                chartConfig.yAxis.crossType = "maximum";

                if (typeof(theLastChartConfigs["yAxis"]) != "undefined") {
                  chartConfig.xAxis.tickInterval = theLastChartConfigs["yAxis"]["tickInterval"];
                  chartConfig.xAxis.max = theLastChartConfigs["yAxis"]["max"];
                }
            }
            chartConfig.series.reverse();
        }

        if (chartConfig.series.length == 1 && typeof (chartConfig.legend) != "undefined") {
          chartConfig.legend.isVisible = false
        }

        return chartConfig;
    }
}
