export class PerformanceContainer {
    constructor() {
        this.performance = 'contacts';
        this.nameMapping = {
            contacts: 'Kontakte',
            percent: 'Anteil %',
            rawSample: 'Fälle',
            projection: 'Unique User Mio'
        }

        this.suffxMapping = {
            percent: '%',
            rawSample: ' Fälle',
            projection: ' Mio'
        }
    }

    getCurrentPerformance() {
        return this.performance;
    }

    getCurrentPerformanceName() {
        return this.nameMapping[this.performance];
    }

    getNameByPerformance(performance) {
        return this.nameMapping[performance];
    }

    getSuffixByPerformance(performance) {
        return this.suffxMapping[performance] ? this.suffxMapping[performance] : '';
    }

    setPerformance(performance) {
        if (this.existPerformance(performance)) {
            this.performance = performance.toLowerCase();
        }
    }

    existPerformance(performance) {
        if (performance && performance.toLowerCase() in this.nameMapping) {
            return true;
        }

        return false;
    }
}
