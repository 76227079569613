
export class ApiBackend {
    constructor() {
        this.onLoadCallback = null;
    }

    setLoadCallback(callback) {
        this.onLoadCallback = callback;
    }

    load(media, date, frame, filter) {
        let params = {
            date: moment(date).format('YYYY-MM-DD'),
            media: media,
            period: frame,
            filter: filter
        }

        $.ajax({ url: '/api/calc/', data: params,
            success: (r) => {
                if (this.onLoadCallback) {
                    this.onLoadCallback(r);
                }
            }
        });
    }

    loadDataUrl(url, date, filter, performance) {
        let params = {
            date: moment(date).format('YYYY-MM-DD'),
            filter: filter
        }

        if (performance != undefined) {
            params.fact = performance
        }

        $.ajax({ url: url, data: params,
            success: (r) => {
                if (this.onLoadCallback) {
                    this.onLoadCallback(r);
                }
            }
        });
    }
}
