export class NumberFormatter {

    formatNumber (inNumber, inFormat) {
        if ( isNaN(inNumber) ) { return '--' }

        if (inNumber === '') { return inNumber }

        if (inFormat) {
            let format = tableWidgetSettings.columns[inFormat].numberFormat
            this.formatNumberDelimiters(format);

            if ("multiplier" in format) { inNumber = inNumber * format.multiplier; }
            return numeral(inNumber).format(format.format);
        }
    }

    formatNumberDelimiters (inFormat) {
        if("ThousandsMarker" in inFormat && "DecimalMarker" in inFormat) {
            numeral.language('de', {
                delimiters: { thousands: inFormat.ThousandsMarker, decimal: inFormat.DecimalMarker },
                abbreviations: { thousand: 'k', million: 'm', billion: 'b', trillion: 't' },
                currency: { symbol: '€' }
            });
            numeral.language('de');
        }
    }
}
